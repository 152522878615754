import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsx mdx */

import DefaultLayout from "/opt/build/repo/site/src/components/episodeLayout.js";
export const _frontmatter = {};

const makeShortcode = name => function MDXDefaultShortcode(props) {
  console.warn("Component '" + name + "' was not imported, exported, or provided by MDXProvider as global scope");
  return <div {...props} />;
};

const layoutProps = {
  _frontmatter
};
const MDXLayout = DefaultLayout;
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">


    <h2>{`Sammanfattning`}</h2>
    <p>{`Vi chockas av EA. Patenttroll åker på brakförlust mot Gnome. Spotify överraskar med otrevligheter och vi fascineras av teckensnitt. `}</p>
    <h2>{`Nyheter`}</h2>
    <ul>
      <li parentName="ul">{`EA släpper C&C och RA open source: `}<a parentName="li" {...{
          "href": "https://www.ea.com/games/command-and-conquer/command-and-conquer-remastered/news/remaster-update-modding"
        }}>{`https://www.ea.com/games/command-and-conquer/command-and-conquer-remastered/news/remaster-update-modding`}</a></li>
      <li parentName="ul">{`GoboLinux 017: `}<a parentName="li" {...{
          "href": "https://gobolinux.org/gobolinux017.html"
        }}>{`https://gobolinux.org/gobolinux017.html`}</a>{` `}</li>
      <li parentName="ul">{`Torvalds går över till AMD: `}<a parentName="li" {...{
          "href": "http://lkml.iu.edu/hypermail/linux/kernel/2005.3/00406.html"
        }}>{`http://lkml.iu.edu/hypermail/linux/kernel/2005.3/00406.html`}</a></li>
      <li parentName="ul">{`Patentsstriden för Shotwell över: `}<a parentName="li" {...{
          "href": "https://www.gnome.org/news/2020/05/patent-case-against-gnome-resolved/"
        }}>{`https://www.gnome.org/news/2020/05/patent-case-against-gnome-resolved/`}</a></li>
    </ul>
    <h2>{`Otrevligheter`}</h2>
    <ul>
      <li parentName="ul">{`Spotify och "exclusive" podcasts (Joe Rogan) :)`}</li>
    </ul>
    <h2>{`Trevligheter`}</h2>
    <ul>
      <li parentName="ul">{`IndestructibleType / OpenType variable fonts: `}<a parentName="li" {...{
          "href": "https://indestructibletype.com"
        }}>{`https://indestructibletype.com`}</a></li>
      <li parentName="ul">{`Wireguard-gränssnitt Subspace: `}<a parentName="li" {...{
          "href": "https://github.com/subspacecommunity/subspace"
        }}>{`https://github.com/subspacecommunity/subspace`}</a></li>
    </ul>
    <h2>{`Utmaningar`}</h2>
    <h3>{`Alex PinePhone`}</h3>
    <ul>
      <li parentName="ul">{`Installerat TelePorts (Ubuntu Touch)`}</li>
    </ul>
    <h3>{`Vim`}</h3>
    <ul>
      <li parentName="ul">{`Alex ska fixa samma setup som Seb`}</li>
      <li parentName="ul">{`Skicka gärna in tips på era Plugins`}</li>
    </ul>
    <h2>{`Kontakta oss`}</h2>
    <ul>
      <li parentName="ul">{`Hemsida: `}<a parentName="li" {...{
          "href": "https://trevligmjukvara.se"
        }}>{`https://trevligmjukvara.se`}</a></li>
      <li parentName="ul">{`Mail: `}<a parentName="li" {...{
          "href": "mailto:kontakt@trevligmjukvara.se"
        }}>{`kontakt@trevligmjukvara.se`}</a></li>
      <li parentName="ul">{`Twitter: `}<a parentName="li" {...{
          "href": "https://twitter.com/trevligmjukvara"
        }}>{`@trevligmjukvara`}</a></li>
      <li parentName="ul">{`Telegram: `}<a parentName="li" {...{
          "href": "https://t.me/trevligmjukvara"
        }}>{`Trevlig Mjukvara`}</a></li>
      <li parentName="ul">{`Mastodon: `}<a parentName="li" {...{
          "href": "https://fikaverse.club/@trevligmjukvara"
        }}>{`@trevligmjukvara`}</a></li>
      <li parentName="ul">{`GitHub: `}<a parentName="li" {...{
          "href": "https://github.com/trevligmjukvara"
        }}>{`Trevlig Mjukvara`}</a></li>
    </ul>
    <h2>{`Tack till`}</h2>
    <ul>
      <li parentName="ul">{`Ljudeffekter från `}<a parentName="li" {...{
          "href": "https://www.zapsplat.com/"
        }}>{`https://www.zapsplat.com/`}</a></li>
      <li parentName="ul">{`Musik från `}<a parentName="li" {...{
          "href": "https://filmmusic.io"
        }}>{`https://filmmusic.io`}</a><ul parentName="li">
          <li parentName="ul">{`"Pixelland" av Kevin MacLeod`}</li>
          <li parentName="ul">{`"NewsSting" av Kevin MacLeod`}</li>
          <li parentName="ul">{`"Toccata and Fugue in D Minor" av Kevin MacLeod`}</li>
          <li parentName="ul">{`"Beautiful World - Epic and Uplifting Motivational Trailer" av Rafael Krux`}</li>
          <li parentName="ul">{`"Black Knight" av Rafael Krux`}</li>
          <li parentName="ul">{`"Pixel Peeker Polka - Faster" av Kevin MacLeod`}</li>
          <li parentName="ul">{`Licens för alla: CC BY 4.0 (`}<a parentName="li" {...{
              "href": "http://creativecommons.org/licenses/by/4.0/"
            }}>{`http://creativecommons.org/licenses/by/4.0/`}</a>{`)`}</li>
        </ul></li>
      <li parentName="ul">{`Grafik och font i loggan:`}<ul parentName="li">
          <li parentName="ul">{`Ok-emoji: emojione version 2.2.7, `}<a parentName="li" {...{
              "href": "https://commons.wikimedia.org/wiki/File:Emojione_1F44C.svg"
            }}>{`https://commons.wikimedia.org/wiki/File:Emojione_1F44C.svg`}</a>{` (CC BY 4.0)`}</li>
          <li parentName="ul">{`Font: Sulphur Point av Dale Sattler, `}<a parentName="li" {...{
              "href": "https://fonts.google.com/specimen/Sulphur+Point"
            }}>{`https://fonts.google.com/specimen/Sulphur+Point`}</a>{` (OFL)`}</li>
        </ul></li>
    </ul>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      